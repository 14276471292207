<template>
  <div class="page-content">
    <page-breadcrumb title="Survey" class="mb-2" />

    <data-create-popup title="Create Survey"
      :schema="create_schema" :default-data="default_data"
      :create-data-fn="createItem" @create="$refs.survey_list.getList()"
    />
    <data-table-ssr id="survey_list" ref="survey_list" :limit-base="9" pagination
      :columns="fields" :get-list-fn="getList"
      :to-edit-fn="toEditPage"
    />
  </div>
</template>

<script>
import service from '../service'

export default {
  data(){
    return{
      create_schema: [
        {
          cols: 12,
          fields: [
            { label: 'Title', field: 'title', input_type: 'text', validate: { required: true } },
            { label: 'Type', field: 'type', input_type: 'select',
              options: [
                {text: "Pronun Survey", value: 1},
                {text: "Breaking Survey", value: 2},
                {text: "Arena Survey", value: 3},
                {text: "Monthly Survey", value: 4},
                {text: "Other Survey", value: 5},
                {text: "Social Plus Survey", value: 6},
                {text: "MANUAL_TRIGGER", value: 7},
              ],
              validate: { required: true }},
            { label: 'Key', field: 'key', input_type: 'text', validate: { required: true } },
            { label: 'Language', field: 'language', input_type: 'select', options: this.$store.getters['language/language_options'], validate: { required: true }},
            { label: 'Subtitle', field: "sub_title", input_type: 'ui-component'},
            { label: 'Reward Type', 'field': 'reward_type', input_type: 'select', options: [{text: "Token", value: 1}, {text: "Gem", value: 2}]},
            { label: 'Reward Amount', field: 'reward_amount', input_type: 'number', validate: { required: true } },
            { label: 'Start Time', field: 'start_time', input_type: 'datetime', displayCond(model){ return model.type != 4}},
            { label: 'End Time', field: 'end_time', input_type: 'datetime', displayCond(model){ return model.type != 4}},
            { label: 'Send Time', field: 'send_time', input_type: 'datetime', displayCond(model){ return model.type === 5}},
            { label: 'Send Day', field: 'send_time_month_day', input_type: 'select', options: [], displayCond(model){ return model.type === 4}},
            { label: 'Send Hour', field: 'send_hour', input_type: 'select', options: [], displayCond(model){ return model.type == 4}},
          ]
        }
      ],
      fields: [
        { label: 'Title', field: 'title', input_type: 'text' },
        { label: 'Type', field: 'type', input_type: 'select',
          options: [
            {text: "Pronun Survey", value: 1},
            {text: "Breaking Survey", value: 2},
            {text: "Arena Survey", value: 3},
            {text: "Monthly Survey", value: 4},
            {text: "Other Survey", value: 5},
            {text: "Social Plus Survey", value: 6},
            {text: "MANUAL_TRIGGER", value: 7},
          ]
          },
        { label: 'Key', field: 'key'},
        { label: 'Language', field: 'language', input_type: 'select', options: this.$store.getters['language/language_options'], validate: { required: true }},
        { label: 'Start Time', field: 'start_time', input_type: 'datetime' },
        { label: 'End Time', field: 'end_time', input_type: 'datetime' },
        { label: 'Created At', field: 'createdAt', input_type: 'datetime' },
      ],
      total: 0,
      default_data: {
				title: "",
				sub_title: { type: 'multiple-title', titles: [''] },
				key: "",
				reward: {
					type: 1,
					amount: 0
				},
				start_time: null,
				end_time: null,
        send_time: null
			}
    }
  },
  computed: {
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    for(let i = 1; i <= 31; i++){
      this.create_schema[0].fields[10].options.push({text: i, value: i})
    };
    for(let i = 1; i <= 24; i++){
      this.create_schema[0].fields[11].options.push({text: i, value: i})
    };
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create(data);
      this.$router.push({ name: 'survey-detail', params: { survey_id: new_item._id } });
    },
    async getList({ limit, page, query }) {
      let response = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit
      });
      let list = [], total = 0;
      if (response.data.type === "DATA") {

        list = response.data.data.list;
        total = response.data.data.total;
        this.total = total;
      }
      return { list, total };
    },
    toEditPage({_id}) {
      this.$router.push({ name: 'survey-detail', params: { survey_id: _id } });
    },
  }
}
</script>
